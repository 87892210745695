import React, { useReducer } from "react"
import { navigate } from "gatsby"
import axios from "axios"

import InputText from "./inputText"
import Textarea from "./textarea"
// import InputCheckbox from "./inputCheckbox"

import styles from "./recruitForm.module.scss"
import radioStyles from "./inputRadio.module.scss"
import checkboxStyles from "./inputCheckbox.module.scss"

const recruitFunction = "/.netlify/functions/recruit"

const ContactForm = () => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "input":
          return {
            ...state,
            fields: {
              ...state.fields,
              [action.fieldName]: action.value,
            },
          }
        case "submitted":
          return {
            ...state,
            submitting: true,
            success: false,
            errorMessage: null,
          }
        case "succeeded":
          return {
            ...state,
            submitting: false,
            success: true,
            errorMessage: null,
            data: action.data,
          }
        case "failed":
          return {
            ...state,
            submitting: false,
            errorMessage: action.message || "Error. Try again later.",
            success: false,
          }
        default:
          return state
      }
    },
    {
      fields: {
        name: "",
        email: "",
        company: "",
        title: "",
        organizationSize: "",
        managesBenefits: "",
        roleDescription: "",
        benefitCategories: [],
        weekAvailability: [],
        notes: "",
      },
      submitting: false,
      success: false,
      errorMessage: null,
      data: null,
    }
  )

  const handleCheckboxChange = event => {
    const value = event.target.value
    const currentState = state.fields[event.target.name]

    let newValue = currentState.includes(value)
      ? currentState.filter(val => val !== value)
      : [...currentState, value]

    if (newValue.length > 1 && newValue.includes("None")) {
      newValue = newValue.filter(val => val !== "None")
    }

    dispatch({
      type: "input",
      fieldName: event.target.name,
      value: newValue,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    dispatch({ type: "submitted" })

    axios
      .post(recruitFunction, {
        fields: state.fields,
      })
      .then(data => {
        if (data.data === "Disqualified") {
          navigate("/benefits-research/success?disqualified=true")
        } else {
          navigate("/benefits-research/success")
        }
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: "failed" })
      })
  }

  return (
    <form
      method="post"
      action="/contact/success"
      name="Contact"
      onSubmit={handleSubmit}
    >
      <h2 className="h-6">Apply to Participate</h2>
      <p className="body-small mb-2">
        All fields are required unless indicated otherwise.
      </p>
      <div className="mb-2">
        <div className="columns">
          <div className="column is-12-mobile is-6-tablet">
            <InputText
              label="First and Last Name"
              name="name"
              id="recruit-form-name"
              type="text"
              placeholder=""
              required={true}
              block={true}
              background="dark-green"
              onChange={value => {
                dispatch({ type: "input", fieldName: "name", value })
              }}
              value={state.fields.name}
            />
          </div>
          <div className="column is-12-mobile is-6-tablet">
            <InputText
              label="Preferred Email"
              name="email"
              id="recruit-form-email"
              type="email"
              placeholder=""
              required={true}
              block={true}
              background="dark-green"
              onChange={value => {
                dispatch({ type: "input", fieldName: "email", value })
              }}
              value={state.fields.email}
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="columns">
          <div className="column is-12-mobile is-6-tablet">
            <InputText
              label="Company"
              name="company"
              id="recruit-form-company"
              type="text"
              placeholder=""
              required={true}
              block={true}
              background="dark-green"
              onChange={value => {
                dispatch({ type: "input", fieldName: "company", value })
              }}
              value={state.fields.company}
            />
          </div>
          <div className="column is-12-mobile is-6-tablet">
            <InputText
              label="Title"
              name="title"
              id="recruit-form-title"
              type="text"
              placeholder=""
              required={true}
              block={true}
              background="dark-green"
              onChange={value => {
                dispatch({ type: "input", fieldName: "title", value })
              }}
              value={state.fields.title}
            />
          </div>
        </div>
      </div>

      {/* 
        ORGANIZATION SIZE
      */}
      <div className="mb-6">
        <h3 className="mb-2">What is the size of your organization?</h3>
        <div>
          <label className={radioStyles.radio}>
            <input
              className="screen-reader-only"
              type="radio"
              name="organizationSize"
              id="recruit-form-org-size-sm"
              value="0-499"
              required={true}
              checked={state.fields.organizationSize === "0-499"}
              onChange={(event, value) =>
                dispatch({
                  type: "input",
                  fieldName: "organizationSize",
                  value: event.target.value,
                })
              }
            />
            <span>0-499</span>
          </label>
        </div>
        <div>
          <label className={radioStyles.radio}>
            <input
              className="screen-reader-only"
              type="radio"
              name="organizationSize"
              id="recruit-form-org-size-md"
              value="500-999"
              required={true}
              checked={state.fields.organizationSize === "500-999"}
              onChange={(event, value) =>
                dispatch({
                  type: "input",
                  fieldName: "organizationSize",
                  value: event.target.value,
                })
              }
            />
            <span>500-999</span>
          </label>
        </div>
        <div>
          <label className={radioStyles.radio}>
            <input
              className="screen-reader-only"
              type="radio"
              name="organizationSize"
              id="recruit-form-org-size-lg"
              value="1000+"
              required={true}
              checked={state.fields.organizationSize === "1000+"}
              onChange={(event, value) =>
                dispatch({
                  type: "input",
                  fieldName: "organizationSize",
                  value: event.target.value,
                })
              }
            />
            <span>1000+</span>
          </label>
        </div>
      </div>

      {/* 
        MANAGES BENEFITS
      */}
      <div className="mb-6">
        <h3 className="mb-1">Do you manage benefits in your current role?</h3>
        <p className="body-medium mb-2">
          i.e., Do you make decisions about which products are included in an
          employee benefits package?
        </p>
        <div>
          <label className={radioStyles.radio}>
            <input
              className="screen-reader-only"
              type="radio"
              name="managesBenefits"
              id="recruit-form-manages-y"
              value="Yes"
              required={true}
              checked={state.fields.managesBenefits === "Yes"}
              onChange={(event, value) =>
                dispatch({
                  type: "input",
                  fieldName: "managesBenefits",
                  value: event.target.value,
                })
              }
            />
            <span>Yes</span>
          </label>
        </div>
        <div>
          <label className={radioStyles.radio}>
            <input
              className="screen-reader-only"
              type="radio"
              name="managesBenefits"
              id="recruit-form-manages-n"
              value="No"
              required={true}
              checked={state.fields.managesBenefits === "No"}
              onChange={(event, value) =>
                dispatch({
                  type: "input",
                  fieldName: "managesBenefits",
                  value: event.target.value,
                })
              }
            />
            <span>No</span>
          </label>
        </div>
      </div>

      {/* 
        ROLE DESCRIPTION
      */}
      <div className="mb-6">
        <h3 className="mb-2">
          Which of the following best describes your role in choosing health
          insurance benefits and/or wellness benefits for employees?
        </h3>
        <div>
          <label className={radioStyles.radio}>
            <input
              className="screen-reader-only"
              type="radio"
              name="roleDescription"
              id="recruit-form-role-desc-sole"
              value="Sole decision maker"
              required={true}
              checked={state.fields.roleDescription === "Sole decision maker"}
              onChange={(event, value) =>
                dispatch({
                  type: "input",
                  fieldName: "roleDescription",
                  value: event.target.value,
                })
              }
            />
            <span>Sole decision maker</span>
          </label>
        </div>
        <div>
          <label className={radioStyles.radio}>
            <input
              className="screen-reader-only"
              type="radio"
              name="roleDescription"
              id="recruit-form-role-desc-participates"
              value="Participate in decision making"
              required={true}
              checked={
                state.fields.roleDescription ===
                "Participate in decision making"
              }
              onChange={(event, value) =>
                dispatch({
                  type: "input",
                  fieldName: "roleDescription",
                  value: event.target.value,
                })
              }
            />
            <span>Participate in decision making</span>
          </label>
        </div>
        <div>
          <label className={radioStyles.radio}>
            <input
              className="screen-reader-only"
              type="radio"
              name="roleDescription"
              id="recruit-form-role-desc-influences"
              value="Influence decisions"
              required={true}
              checked={state.fields.roleDescription === "Influence decisions"}
              onChange={(event, value) =>
                dispatch({
                  type: "input",
                  fieldName: "roleDescription",
                  value: event.target.value,
                })
              }
            />
            <span>Influence decisions</span>
          </label>
        </div>
        <div>
          <label className={radioStyles.radio}>
            <input
              className="screen-reader-only"
              type="radio"
              name="roleDescription"
              id="recruit-form-role-desc-not-involved"
              value="Not involved in decision making"
              required={true}
              checked={
                state.fields.roleDescription ===
                "Not involved in decision making"
              }
              onChange={(event, value) =>
                dispatch({
                  type: "input",
                  fieldName: "roleDescription",
                  value: event.target.value,
                })
              }
            />
            <span>Not involved in decision making</span>
          </label>
        </div>
      </div>

      {/* 
        BENEFIT CATEGORIES
      */}
      <div className="mb-6">
        <h3 className="mb-1">
          Which of the following categories of benefits do you manage?
        </h3>
        <p className="body-medium italic mb-2">Select all that apply.</p>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="benefitCategories"
              id="recruit-form-benefit-cat-health"
              value="Health insurance"
              checked={state.fields.benefitCategories.includes(
                "Health insurance"
              )}
              onChange={handleCheckboxChange}
            />
            <span>Health insurance</span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="benefitCategories"
              id="recruit-form-benefit-cat-dental"
              value="Dental insurance"
              checked={state.fields.benefitCategories.includes(
                "Dental insurance"
              )}
              onChange={handleCheckboxChange}
            />
            <span>Dental insurance</span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="benefitCategories"
              id="recruit-form-benefit-cat-vision"
              value="Vision insurance"
              checked={state.fields.benefitCategories.includes(
                "Vision insurance"
              )}
              onChange={handleCheckboxChange}
            />
            <span>Vision insurance</span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="benefitCategories"
              id="recruit-form-benefit-cat-wellness"
              value="Physical health and/or wellness programs"
              checked={state.fields.benefitCategories.includes(
                "Physical health and/or wellness programs"
              )}
              onChange={handleCheckboxChange}
            />
            <span>Physical health and/or wellness programs</span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="benefitCategories"
              id="recruit-form-benefit-cat-mental"
              value="Employee Assistance Programs or other mental health programs"
              checked={state.fields.benefitCategories.includes(
                "Employee Assistance Programs or other mental health programs"
              )}
              onChange={handleCheckboxChange}
            />
            <span>
              Employee Assistance Programs or other mental health programs
            </span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="benefitCategories"
              id="recruit-form-benefit-cat-rewards"
              value="Employee rewards"
              checked={state.fields.benefitCategories.includes(
                "Employee rewards"
              )}
              onChange={handleCheckboxChange}
            />
            <span>Employee rewards</span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="benefitCategories"
              id="recruit-form-benefit-cat-other"
              value="Other (elaborate in the last notes field)"
              checked={state.fields.benefitCategories.includes(
                "Other (elaborate in the last notes field)"
              )}
              onChange={handleCheckboxChange}
            />
            <span>Other (elaborate in the last notes field)</span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="benefitCategories"
              id="recruit-form-benefit-cat-none"
              value="None"
              checked={state.fields.benefitCategories.includes("None")}
              onChange={() => {
                dispatch({
                  type: "input",
                  fieldName: "benefitCategories",
                  value: ["None"],
                })
              }}
            />
            <span>None</span>
          </label>
        </div>
      </div>

      {/* 
        WEEK AVAILABILITY
      */}
      <div className="mb-6">
        <h3 className="mb-1">
          Which of the following weeks you would be available for a one-hour
          Zoom interview?
        </h3>
        <p className="body-medium italic mb-2">Select all that apply.</p>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="weekAvailability"
              id="recruit-form-week-1"
              value="February 1-4"
              checked={state.fields.weekAvailability.includes("February 1-4")}
              onChange={handleCheckboxChange}
            />
            <span>February 1-4</span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="weekAvailability"
              id="recruit-form-week-2"
              value="February 7-11"
              checked={state.fields.weekAvailability.includes("February 7-11")}
              onChange={handleCheckboxChange}
            />
            <span>February 7-11</span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="weekAvailability"
              id="recruit-form-week-3"
              value="February 14-18"
              checked={state.fields.weekAvailability.includes("February 14-18")}
              onChange={handleCheckboxChange}
            />
            <span>February 14-18</span>
          </label>
        </div>
        <div>
          <label className={checkboxStyles.checkbox}>
            <input
              className="screen-reader-only"
              type="checkbox"
              name="weekAvailability"
              id="recruit-form-week-none"
              value="None"
              checked={state.fields.weekAvailability.includes("None")}
              onChange={() => {
                dispatch({
                  type: "input",
                  fieldName: "weekAvailability",
                  value: ["None"],
                })
              }}
            />
            <span>None</span>
          </label>
        </div>
      </div>

      <div className="mb-7">
        <div className="mb-1">
          <Textarea
            label="Anything else we should know? (optional)"
            name="notes"
            id="recruit-form-notes"
            // required={true}
            block={true}
            onChange={value => {
              dispatch({ type: "input", fieldName: "notes", value })
            }}
            value={state.fields.notes}
          />
        </div>
        <p className="body-medium italic">
          Ask any questions or feel free to share additional background or
          insights.
        </p>
      </div>
      <button className="button" type="submit">
        Submit
      </button>
      <div className={styles.formResponse}>
        <div className="error">{state.errorMessage}</div>
      </div>
    </form>
  )
}

export default ContactForm
