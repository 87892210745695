import React from "react"
import { Link } from "gatsby"

import Layout, { zeusAltTheme } from "../../components/layout"
import RecruitForm from "../../components/recruitForm"
import styles from "./index.module.scss"

const RecruitPage = () => {
  return (
    <Layout theme={zeusAltTheme}>
      <div className="page-content-module">
        <div className="mb-20 wrapper">
          <div className="mb-6">
            <div className="columns">
              <div className="column is-12-mobile is-10-tablet is-8-widescreen is-offset-1-widescreen">
                <div className="mb-2">
                  <h1 className="h-2 h--cms-title">
                    Shape the future of <em>employee benefits</em>.
                  </h1>
                </div>
                <div className="mb-4">
                  <h2 className="h-6 h--cms-title">
                    Help design your perfect product and get rewarded for your
                    time.
                  </h2>
                </div>
                <div className="mb-8">
                  <p>
                    An exciting new health and wellness benefits program is in
                    the works and we're looking for HR benefits professionals to
                    let us know what they think. Your feedback will help ensure
                    we offer a final product that meets your needs and the needs
                    of your employees. If selected, in exchange for 60 minutes
                    of your time and expertise, you'll be paid an hororarium of
                    $200 via American Express gift card.
                  </p>
                </div>
                <div className="user-content mb-10">
                  <hr />
                  <h4>Who We Are</h4>
                  <p>
                    In order to ensure we receive honest, unbiased feedback, the
                    brand behind the product will remain anonymous. Zeus Jones,
                    a Minneapolis-based strategy, creative and design agency, is
                    facilitating this research on their behalf. Our goal is to
                    tap into your experience, goals and challenges to better
                    understand how our client can design a product that delivers
                    what you need.
                  </p>
                  <h4>How it Works</h4>
                  <ol>
                    <li>Complete the application form below.</li>
                    <li>
                      If you qualify, someone from the Zeus Jones team will be
                      in touch within the next 7 business days. (Note: we will
                      only be selecting a total of 10 participants.)
                    </li>
                    <li>
                      We will schedule a 60-minute Zoom interview between
                      February 1-18.
                    </li>
                    <li>
                      After the interview is complete, we will send a $200
                      American Express gift card to your preferred email.
                    </li>
                  </ol>
                </div>
                <hr />
              </div>
            </div>
          </div>

          <div className="columns is-multiline">
            <div
              className={`column is-12-mobile is-10-tablet is-8-widescreen is-offset-1-widescreen ${styles.formColumn}`}
            >
              <div className={styles.form}>
                <RecruitForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RecruitPage
